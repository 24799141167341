<!--
 * 
 *邀请有奖
-->

<template>
  <div>
    <div id="wishRecord" :class="!isApp ? 'appTop' : ''">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="list.length ? '-到底啦-' : ''"
        offset="200"
        class="recordList"
        @load="onLoad"
      >
        <div class="recordItem" v-for="(item, index) in list" :key="index">
          <div class="success">
            <div>单号{{ item.order_no }}</div>
            <div>{{ getTime(item.success_time) }}</div>
          </div>
          <div class="itemLeft">
            <div class="itemImgBox">
              <img
                :src="item.platform == 1 ? item.image_thumb : item.yihua_image"
                class="itemImg"
              />
              <div class="wishType">{{ item.wish_type == 1 ? '获赠' : '赠出' }}</div>
              <img
                class="levelsIcon"
                v-if="levelsChange(item.levels) && item.config_type === 1"
                :src="levelsChange(item.levels)"
                alt=""
                srcset=""
              />
            </div>

            <div class="itemInfo">
              <div class="nameBox">
                <span class="name">{{ item.config_name }} x {{ item.success_num }}</span>
                <div class="">
                  <span style="color: #3cdbe8; font-weight: 600">¥{{ item.unit_price / 100 }}</span
                  >/份
                </div>
              </div>

              <div class="price nameBox">
                <span class="count"
                  >{{ item.platform == 1 ? '看漫' : '一画' }}数藏 - 《{{
                    item.display_name
                  }}》</span
                >
                <div class="" style="color: #fff">
                  感谢费：<span style="color: #3cdbe8; font-weight: 600"
                    >¥{{ item.unit_price / 100 }}</span
                  >/份
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>

      <div v-if="!list.length" class="dataNullBox">
        <div>
          <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text">暂无记录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { getusersuccesswishingorders } from '@/service/wish';
import moment from 'moment';
import { nftUtils } from '@/utils';
import shareBtn from '@/components/inviteSharePoster/inviteSharePoster';

export default {
  name: 'wish',
  components: {
    // shareBtn
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      list: [],
      finished: false,
      page: 1,
      loading: true,
      isApp: nftUtils.isInApp()
    });

    const getList = () => {
      getusersuccesswishingorders({
        page: state.page,
        page_size: 10
      }).then(res => {
        console.log(res);
        if (res.status === 0) {
          state.pageLoading = false;
          state.list = state.list.concat(res.data || []);
          state.loading = false;
          if (!res.data || !res.data.length) {
            state.finished = true;
          }
        }
      });
    };

    const onLoad = () => {
      if (!state.finished && state.list.length) {
        console.log(state.page);
        state.page = state.page + 1;
      }
      getList();
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
      return moment(time).format(format);
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    onMounted(() => {
      // console.log(route.query.startTime);
      nftUtils.setcnzzRecordByBtn('进入愿望达成记录页面');

      state.loading = true;
      state.finished = false;
      state.list = [];
      state.page = 1;

      onLoad();
      console.log('nftUtils', nftUtils.levelsChange(1));
    });

    return {
      ...toRefs(state),
      onLoad,
      getTime,
      levelsChange
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}

.appTop {
  padding-top: 50px;
}

.recordList {
  padding: 0 20px;
  .recordItem {
    // display: flex;
    // justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    padding-bottom: 11px;
    border-bottom: 1px solid #494359;

    .itemLeft {
      display: flex;
      align-items: center;
      .itemImg {
        width: 48px;
        height: 48px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        object-fit: cover;
      }

      .itemImgBox {
        position: relative;

        .levelsIcon {
          position: absolute;
          width: 22px;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
        }
        .wishType {
          position: absolute;
          top: 4px;
          left: 0;
          display: inline-block;
          padding: 0px 3px;
          // height: 15px;
          // line-height: 15px;
          background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
          border-radius: 4px 4px 4px 4px;
          font-size: 8px;
          font-weight: 400;
          color: #000000;
          margin-right: 6px;
        }
      }

      .itemInfo {
        width: 100%;
        margin-left: 12px;
        .nameBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          white-space: nowrap;

          .name {
            display: inline-block;
            max-width: 250px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }

          .count {
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.7);
          }
        }

        .price {
          display: flex;
          align-items: center;
          margin-top: 6px;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }

    .success {
      text-align: right;
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      line-height: 36px;
      color: rgba(255, 255, 255, 0.7);
      font-size: 11px;
    }
  }
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 134px;
  background: linear-gradient(180deg, rgba(28, 23, 42, 0) 0%, #000000 100%);
}

.dataNullBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
<style lang="scss">
#wishRecord {
  .vantPop {
    background: transparent !important;
  }
  .posterBtn {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 252px;
    height: 44px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    font-weight: 500;
    color: #1c172a;
    line-height: 44px;
    text-align: center;
    opacity: 1;
  }
  .qrcodeImg {
    top: 352px;
  }
  .disPosterBtn {
    opacity: 0.6;
    font-size: 16px;
  }
}
</style>
